// legacy path parameters, format: key_value1_value2...
import { PathParameter } from '../types';

const PROFESSIONAL_SECTIONS_ANCHOR = 'sections';

export const LEGACY_PATH_PARAM_KEYS: Readonly<string[]> = [
    'company',
    'category',
    'sort',
    'profession',
];

// new single value path parameters, format: key-value
export const SINGLE_VALUE_PATH_PARAM_KEYS: Readonly<string[]> = [
    'colors',
    'materials',
    'location',
];

export const PATH_PARAMETERS_ORDER: Readonly<Record<string, number>> = {
    company: 0,
    category: 1,
    profession: 2,
    colors: 3,
    materials: 4,
    location: 5,
    sort: 6,
};

const SINGLE_VALUE_SEARCH_PARAM_KEYS: Readonly<string[]> = [
    'page',
    'sort',
    'pageSize',
    'updateUserPrefs',
];

export type URLParams = Record<string, string | string[] | number | number[]>;

export interface URLInfo {
    basePath: string;
    params: URLParams;
    isSuppliersEndpoint?: boolean;
}

/**
 * Parse the URL get params and base path info
 * @param url
 * @returns
 */
export const parseURL = (
    url: string,
    legacyPathParameterKeys = LEGACY_PATH_PARAM_KEYS,
    singleValuePathParameterKeys = SINGLE_VALUE_PATH_PARAM_KEYS
): URLInfo => {
    // HACK: for old parseURL we need to look for /suppliers to be able to get an original url
    // this caused by parseURL() is being used in many places in the codebase, uncontrollably
    // ideally we need to parseURL() only once, on request level
    const isSuppliersEndpoint = url.includes('/suppliers');
    const cleanedUrlString = isSuppliersEndpoint
        ? url.replace('/suppliers', '')
        : url;
    ///////////////////////////////////////////////////////////////////////////////////////////

    const startWithSlash = cleanedUrlString.startsWith('/');

    let params: URLParams = {};

    const [allPath, searchParamsStr] = decodeURI(cleanedUrlString).split('?');

    if (!allPath) {
        return {
            basePath: cleanedUrlString,
            params,
        };
    }

    // get all search parameters
    const searchParams = new URLSearchParams(searchParamsStr);
    for (const key of searchParams.keys()) {
        if (SINGLE_VALUE_SEARCH_PARAM_KEYS.includes(key)) {
            const value = searchParams.get(key);
            if (value) {
                params[key] = value;
            }
        } else {
            const values = searchParams.getAll(key);
            if (values.length) {
                params[key] = values;
            }
        }
    }

    // get all path parameters and base path
    const paths = allPath.split('/').filter(p => !!p);
    const basePaths: string[] = [];
    let foundPathParams = false;
    paths.forEach(path => {
        const pathParams = parsePathParameters(
            path,
            legacyPathParameterKeys,
            singleValuePathParameterKeys
        );
        if (pathParams) {
            foundPathParams = true;
            // override
            params = { ...params, ...pathParams };
        } else {
            if (!foundPathParams) basePaths.push(path);
        }
    });

    const basePath = `${startWithSlash ? '/' : ''}${basePaths.join('/')}`;

    // tidy up page value
    if (params['page']) {
        const page = Number(params['page']);
        if (page) {
            params['page'] = page;
        } else {
            delete params['page'];
        }
    }

    return { basePath, params, isSuppliersEndpoint };
};

const parseLegacyPathParameters = (
    path: string,
    legacyPathParameterKeys: Readonly<string[]>
): URLInfo['params'] | undefined => {
    const matchParam = legacyPathParameterKeys.find(param =>
        path.startsWith(param + '_')
    );

    if (!matchParam) {
        return undefined;
    }

    const values = path
        .replace(matchParam + '_', '')
        .split('_')
        .filter(v => !!v);

    if (!values.length) {
        return {};
    }

    return {
        [matchParam]: SINGLE_VALUE_SEARCH_PARAM_KEYS.includes(matchParam)
            ? values[0]!
            : values,
    };
};

const parseSingleValuePathParameters = (
    path: string,
    singleValuePathParameterKeys: Readonly<string[]>
): URLInfo['params'] | undefined => {
    const matchParam = singleValuePathParameterKeys.find(param =>
        path.startsWith(param + '-')
    );

    if (!matchParam) {
        return undefined;
    }

    return {
        [matchParam]: [path.replace(matchParam + '-', '')],
    };
};

const parsePathParameters = (
    path: string,
    legacyPathParameterKeys: Readonly<string[]>,
    singleValuePathParameterKeys: Readonly<string[]>
): URLInfo['params'] | undefined => {
    return (
        parseLegacyPathParameters(path, legacyPathParameterKeys) ??
        parseSingleValuePathParameters(path, singleValuePathParameterKeys)
    );
};

/**
 * Generate the URL based on the new parameters
 * @param url                       original url, can include path and query parameters
 * @param newParams                 new parameters
 * @param legacyPathParameters      legacy path parameter keys, will create path param add to base path if exist in the params, format: base-path/key1_value1_value2/key2_value1
 * @param singleValuePathParameters single value parameter keys, will create path param add to base path if exist in the params and only have one value, format: base-path/key1-value
 * @returns
 */
export const generateURL = (
    url: string,
    newParams?: URLParams,
    overrideParams = false,
    legacyPathParameterKeys = LEGACY_PATH_PARAM_KEYS,
    singleValuePathParameterKeys = SINGLE_VALUE_PATH_PARAM_KEYS,
    pathParamsOrder = PATH_PARAMETERS_ORDER
): string => {
    const {
        basePath,
        params: originalParams,
        isSuppliersEndpoint,
    } = parseURL(url);
    // override original params with new params
    const params = overrideParams
        ? newParams ?? {}
        : { ...originalParams, ...newParams };

    const legacyPathParameters = generateLegacyPathParameters(
        params,
        legacyPathParameterKeys
    );

    const singleValuePathParameters = generateSingleValuePathParameters(
        params,
        singleValuePathParameterKeys
    );

    const searchParams = generateSearchParameters(params)?.toString();

    const pathParameters = legacyPathParameters
        .concat(singleValuePathParameters)
        .sort((a, b) => pathParamsOrder[a.name] - pathParamsOrder[b.name])
        .filter(p => !!p.pathElement)
        .map(p => p.pathElement);

    // restore suppliers endpoint if it was removed
    if (isSuppliersEndpoint) {
        pathParameters.unshift('suppliers');
    }

    let path = [basePath]
        .concat(pathParameters)
        .filter(path => !!path)
        .join('/');

    if (searchParams) path = `${path}?${searchParams.toString()}`;

    // Check if we're in the professional section (URL ends with /professional or /professional/)
    const isProfessionalPath = /\/professional(\/|$)/.test(path);
    return isProfessionalPath
        ? `${path}${
              path.includes('#') ? '' : `#${PROFESSIONAL_SECTIONS_ANCHOR}`
          }`
        : path;
};

const generateSearchParameters = (
    params: URLInfo['params']
): URLSearchParams | undefined => {
    if (!params) {
        return undefined;
    }
    const newSearchParams = new URLSearchParams();

    Object.keys(params).forEach(key => {
        const values = params[key];
        if (Array.isArray(values)) {
            values.forEach(value =>
                newSearchParams.append(key, value.toString())
            );
        } else if (values) {
            newSearchParams.set(key, values.toString());
        }
    });

    return newSearchParams;
};

const generateSingleValuePathParameters = (
    params: URLInfo['params'],
    singleValuePathParameterKeys: Readonly<string[]>
): PathParameter[] => {
    if (!params) {
        return [];
    }

    const pathParameters: PathParameter[] = [];
    const matchKeys: string[] = [];

    singleValuePathParameterKeys.forEach(paramKey => {
        const value = params[paramKey];
        if (!value) return;

        // multiple values, fallback to search parameters
        if (Array.isArray(value) && (value.length > 1 || !value.length)) return;

        matchKeys.push(paramKey);
    });

    if (matchKeys.length !== 1) {
        return [];
    }

    const singleValueKey = matchKeys[0]!;
    const singleValue = params[singleValueKey];
    delete params[singleValueKey];
    const value = Array.isArray(singleValue) ? singleValue[0] : singleValue;

    pathParameters.push({
        name: singleValueKey,
        pathElement: `${singleValueKey}-${value}`,
    });

    return pathParameters;
};

const generateLegacyPathParameters = (
    params: URLInfo['params'],
    legacyPathParameterKeys: Readonly<string[]>
): PathParameter[] => {
    if (!params) {
        return [];
    }

    const pathParameters: PathParameter[] = [];

    legacyPathParameterKeys.forEach(paramKey => {
        const value = params[paramKey];
        // will add to the path, remove from the params
        delete params[paramKey];
        if (!value) return;

        if (Array.isArray(value)) {
            if (value.length) {
                pathParameters.push({
                    name: paramKey,
                    pathElement: `${paramKey}_${value.join('_')}`,
                });
            }
        } else {
            pathParameters.push({
                name: paramKey,
                pathElement: `${paramKey}_${value}`,
            });
        }
    });

    return pathParameters;
};

export const getString = (
    params: URLParams,
    key: string
): string | undefined => {
    const value = params[key];
    if (!value || (Array.isArray(value) && !value.length) || !value) {
        return undefined;
    }
    if (Array.isArray(value)) {
        return value[0]?.toString();
    } else {
        return value.toString();
    }
};

export const getNumber = (
    params: URLParams,
    key: string
): number | undefined => {
    const value = params[key];
    const singleValue = Array.isArray(value) ? value[0] : value;

    if (isNaN(Number(singleValue))) {
        return undefined;
    }
    return Number(value);
};

export const getBoolean = (
    params: URLParams,
    key: string
): boolean | undefined => {
    const value = params[key];
    if (!value || (Array.isArray(value) && !value.length) || !value) {
        return false;
    }
    if (Array.isArray(value)) {
        return value[0] === '1' || value[0] === 1;
    } else {
        return value === '1' || value === 1;
    }
};

export const getStringArray = (
    params: URLParams,
    key: string
): string[] | undefined => {
    const value = params[key];
    if (!value || (Array.isArray(value) && !value.length) || !value) {
        return undefined;
    }
    if (Array.isArray(value)) {
        return value.map(v => v.toString());
    } else {
        return [value.toString()];
    }
};

export const getNumberRange = (
    params: URLParams,
    key: string,
    multiplier = 1
): [number, number] | undefined => {
    const value = params[key];
    const singleValue = Array.isArray(value) ? value[0] : value;
    if (typeof singleValue !== 'string') {
        return undefined;
    }
    const rangeStrings = singleValue.split(',');
    if (rangeStrings.length !== 2) {
        return undefined;
    }

    if (rangeStrings.some(str => isNaN(parseFloat(str)))) {
        return undefined;
    }
    const range = rangeStrings.map(value => parseFloat(value) * multiplier);
    return range.sort((a, b) => a - b) as [number, number];
};
